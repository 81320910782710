import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import '../styles/ThreeDArea.css'; 

function ThreeDArea({ modelData }) {
  const mountRef = useRef(null);

  useEffect(() => {
    const currentMount = mountRef.current;
    const width = currentMount.clientWidth;
    const height = currentMount.clientHeight;

    // Создаем сцену
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0xffffff); 

    // Настраиваем камеру
    const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
    camera.position.set(0, 5, 10);

    // Создаем рендерер
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(width, height);
    currentMount.appendChild(renderer.domElement);

    // Добавляем основное освещение
    const light1 = new THREE.DirectionalLight(0x6699ff, 0.7); 
    light1.position.set(10, 10, 10).normalize();
    scene.add(light1);

    // Добавляем дополнительное освещение для градиента
    const light2 = new THREE.DirectionalLight(0x003366, 0.5); 
    light2.position.set(-10, -10, 10).normalize();
    scene.add(light2);

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.3);
    scene.add(ambientLight);

    // Добавляем OrbitControls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.25;
    controls.enableZoom = true;

    // Если есть данные модели, загружаем их
    if (modelData) {
      try {
        const loader = new GLTFLoader();
        const blob = new Blob([modelData], { type: 'model/gltf-binary' });
        const url = URL.createObjectURL(blob);
        loader.load(
          url,
          (gltf) => {
            // Устанавливаем материал с градиентным эффектом
            gltf.scene.traverse((child) => {
              if (child.isMesh) {
                child.material = new THREE.MeshStandardMaterial({
                  color: 0x6699ff, 
                  emissive: 0x003366, 
                  emissiveIntensity: 0.5, 
                });
              }
            });

            // Добавляем модель в сцену
            scene.add(gltf.scene);

            // Установка модели в горизонтальное положение
            gltf.scene.rotation.x = Math.PI / 2;

            // Центрирование и масштабирование модели
            const box = new THREE.Box3().setFromObject(gltf.scene);
            const center = box.getCenter(new THREE.Vector3());
            const size = box.getSize(new THREE.Vector3());

            const maxDim = Math.max(size.x, size.y, size.z);
            const fov = camera.fov * (Math.PI / 180);
            let cameraZ = Math.abs(maxDim / 2 / Math.tan(fov / 2));

            camera.position.z = cameraZ * 1.5;
            camera.updateProjectionMatrix();

            gltf.scene.position.x = -center.x;
            gltf.scene.position.y = -center.y;
            gltf.scene.position.z = -center.z;

            controls.update();
            renderer.render(scene, camera);
          },
          undefined,
          (error) => {
            console.error('Ошибка при загрузке модели:', error);
            alert('Ошибка при загрузке 3D модели: ' + error.message);
          }
        );
      } catch (error) {
        console.error('Ошибка при создании модели:', error);
        alert('Произошла ошибка при создании модели: ' + error.message);
      }
    }

    // Функция для анимации сцены
    const animate = function () {
      requestAnimationFrame(animate);
      controls.update();
      renderer.render(scene, camera);
    };
    animate();

    // Обновление размера рендера при изменении размера окна
    const handleResize = () => {
      const newWidth = currentMount.clientWidth;
      const newHeight = currentMount.clientHeight;
      camera.aspect = newWidth / newHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(newWidth, newHeight);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      currentMount.removeChild(renderer.domElement);
      window.removeEventListener('resize', handleResize);
    };
  }, [modelData]);

  return <div className="three-d-area" ref={mountRef} />;
}

export default ThreeDArea;