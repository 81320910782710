import React, { useState, useRef } from 'react';
import './styles/App.css';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import DrawingArea from './components/DrawingArea';
import TwoDArea from './components/TwoDArea';
import ThreeDArea from './components/ThreeDArea';

function App() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [shouldUpdate2D, setShouldUpdate2D] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [modelData, setModelData] = useState(null);
  const drawingAreaRef = useRef(null);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handleUploadClick = async () => {
    const stage = drawingAreaRef.current.getStage(); 

    // Создаем слой для фона
    const backgroundLayer = new window.Konva.Layer();
    const backgroundRect = new window.Konva.Rect({
      x: 0,
      y: 0,
      width: stage.width(),
      height: stage.height(),
      fill: 'white',
    });
    backgroundLayer.add(backgroundRect);
    stage.add(backgroundLayer);
    backgroundLayer.moveToBottom();
    stage.draw();

    // Захватываем изображение
    const dataURL = stage.toDataURL({ pixelRatio: 1 });
    const blob = await (await fetch(dataURL)).blob();

    // Удаляем слой фона после захвата изображения
    backgroundLayer.destroy();
    stage.draw();

    setLoading(true);

    const formData = new FormData();
    formData.append('file', blob, 'canvas_image.png');

    console.log('API URL:', process.env.REACT_APP_API_URL);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/generate`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Ошибка при загрузке файла');
      }

      const modelData = await response.arrayBuffer();
      setModelData(modelData);

      setShouldUpdate2D(true);
      setRefreshKey(prevKey => prevKey + 1);

    } catch (error) {
      console.error('Ошибка при загрузке файла:', error.message);
      alert('Произошла ошибка при обработке файла. Пожалуйста, попробуйте снова.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="App">
      <button className="generate-button" onClick={handleUploadClick} disabled={isLoading}>
        {isLoading ? "Генерация..." : "Сгенерировать"}
      </button>

      <button className="instruction-button" onClick={toggleSidebar}>
        {isSidebarOpen ? "Закрыть инструкцию" : "Инструкция"}
      </button>

      <div className="main-content">
        <div className="left-side">
          <DrawingArea ref={drawingAreaRef} />
          <TwoDArea key={refreshKey} shouldUpdate={shouldUpdate2D} />
        </div>
        <div className="right-side">
          <ThreeDArea modelData={modelData} />
        </div>
      </div>

      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <Footer />
    </div>
  );
}

export default App;
