import React, { useState } from 'react';
import '../styles/Sidebar.css';
import CloseIcon from '../icons/close.png';

function Sidebar({ isOpen, toggleSidebar }) {
  const [isInstructionOpen, setInstructionOpen] = useState(false);

  const toggleInstruction = () => {
    setInstructionOpen(!isInstructionOpen);
  };

  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <img src={CloseIcon} alt="Close" className="close-button" onClick={toggleSidebar} />
      <div className="sidebar-content">
        <p>О проекте</p>
        <p><strong>ТрестAI</strong> — это генеративная нейросеть для автоматизации процесса разработки проектной документации (чертежей) и <strong>интерактивных 3D-моделей</strong> для планов квартир, этажей,
          частных или многоквартирных домов, а также других зданий и сооружений.
        </p>
        <p>
          В основу разрабатываемого алгоритма заложен принцип работы GAN (генеративно-состязательная сеть) в сочетании с CNN (сверточная нейронная сеть).
          Благодаря этому происходит объединение двух базовых архитектур, существенно влияющее на точность и качество генерации.
        </p>
        <p>
          Перспективы развития и масштабирования разработки не ограничиваются только строительной отраслью.
          Результаты на текущей стадии разработки доказывают, что нейросеть может быть адаптирована и под другие сферы промышленности:
          машиностроение, вагоностроение, станкостроение, металлургия, сельское хозяйство, – все те, где существует процесс разработки проектной и рабочей документаций.
        </p>
        <p>Текущая версия алгоритма: <strong>TrestAI 0.2.0-alpha (21.08.2024)</strong></p>

        <div className="instruction-section">
          <button className="toggle-instruction-button" onClick={toggleInstruction}>
            {isInstructionOpen ? 'Скрыть инструкцию' : 'Показать инструкцию'}
          </button>
          {isInstructionOpen && (
            <div className="instruction-content">
              <p><strong>Описание областей:</strong></p>
              <ul>
                <li><strong>Область создания моделей:</strong> Здесь вы создаете исходную модель, рисуя ее простейшими прямоугольниками.</li>
                <li><strong>2D-Область:</strong> После генерации в этой области будет отображено сгенерированное 2D-изображение.</li>
                <li><strong>3D-Область:</strong> Здесь будет представлена интерактивная 3D-модель, с которой можно взаимодейстовать.</li>
              </ul>
              <p><strong>Процесс создания модели:</strong></p>
              <ul>
                <li>Используйте Область создания моделей, изображайте прямоугольниками вашу "планиовку".
                Прямоугольники должны плотно прилегать друг к другу без пробелов или даже накладываться один на другой.
                <p>
                <strong>Важно</strong> работать только внутри Области создания моделей, когда вы создаете новую "зону" или "комнату" <strong>необходимо</strong> изменять цвет.
                </p>
                </li>
                <li>Когда ваша модель готова, нажмите на кнопку <strong>Сгенерировать</strong> и дождитесь завершения процесса генерации.</li>
                <li>Если результат вам не понравился, вы можете сбросить модель, нажав на иконку рядом.
                Для отмены последнего действия нажмите комбинацию клавиш <strong>Cmd+Shift+Z</strong>.
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
