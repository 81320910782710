import React, { useState, useEffect } from 'react';
import '../styles/TwoDArea.css';

function TwoDArea({ shouldUpdate }) {
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    if (shouldUpdate) {
      const fetchImage = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/generated_images/list`);
          const data = await response.json();

          const nextIndex = data.max_index;  

          const url = `${process.env.REACT_APP_API_URL}/api/generated_images/${nextIndex}_generated.jpg?timestamp=${new Date().getTime()}&rand=${Math.random()}`;
          setImageUrl(url);

          console.log('New image URL:', url);
        } catch (error) {
          console.error('Ошибка при загрузке изображения:', error);
        }
      };

      fetchImage();
    }
  }, [shouldUpdate]);

  return (
    <div className="two-d-area">
      {imageUrl ? (
        <img src={imageUrl} alt="2D Model" key={imageUrl} />
      ) : (
        <p>Область генерации 2D-изображения</p>
      )}
    </div>
  );
}

export default TwoDArea;