import React from 'react';
import '../styles/Footer.css';
import Logo from '../images/trestai_logo.png';
import TgIcon from '../icons/telegram.png';
import GhIcon from '../icons/github.png';
import Article from '../icons/info.png';

function Footer() {
  return (
    <footer className="footer">
      <img src={Logo} alt="TREST.AI" className="footer-logo" />
      <div className="social-icons">
        <a href="https://t.me/Trest_AI" target="_blank" rel="noopener noreferrer" className="social-icon">
          <img src={TgIcon} alt="Telegram" />
        </a>
        <a href="https://github.com/dormannilya/nagess" target="_blank" rel="noopener noreferrer" className="social-icon">
          <img src={GhIcon} alt="GitHub" />
        </a>
        <a href="https://technosuveren.ru/sozdana-rossijskaja-nejroset-dlja-generacii-stroitelnyh-chertezhej/" target="_blank" rel="noopener noreferrer" className="social-icon">
          <img src={Article} alt="Info" />
        </a>
      </div>
      <div className="footer-text">
        Продукт является <strong>MVP TrestAI 0.2.0-alpha</strong> и содержит неточности.
      </div>
    </footer>
  );
}

export default Footer;