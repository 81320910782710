import React, { useState, useCallback, useEffect, forwardRef } from 'react';
import { Stage, Layer, Rect } from 'react-konva';
import { SketchPicker } from 'react-color';
import '../styles/DrawingArea.css';
import refreshIcon from '../icons/refresh.png'; 

const DrawingArea = forwardRef((props, ref) => {  
  const [rectangles, setRectangles] = useState([]);
  const [newRect, setNewRect] = useState(null);
  const [selectedColor, setSelectedColor] = useState('#000');
  const [isDrawing, setIsDrawing] = useState(false);

  // Функция для удаления последнего прямоугольника
  const undoLastAction = useCallback(() => {
    setRectangles(rectangles.slice(0, -1));
  }, [rectangles]);

  // Обработчик для клавиш
  const handleKeyDown = useCallback((e) => {
    if ((e.ctrlKey || e.metaKey) && e.key === 'z') {
      undoLastAction();
    }
  }, [undoLastAction]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const handleMouseDown = (e) => {
    const { x, y } = e.target.getStage().getPointerPosition();
    setIsDrawing(true);
    setNewRect({
      x,
      y,
      width: 0,
      height: 0,
      fill: selectedColor,
      id: `rect${rectangles.length + 1}`
    });
  };

  const handleMouseMove = (e) => {
    if (!isDrawing) return;
    const { x, y } = e.target.getStage().getPointerPosition();
    const width = x - newRect.x;
    const height = y - newRect.y;
    setNewRect({
      ...newRect,
      width,
      height
    });
  };

  const handleMouseUp = () => {
    setIsDrawing(false);
    setRectangles([...rectangles, newRect]);
    setNewRect(null);
  };

  const clearDrawing = () => {
    setRectangles([]); 
  };

  return (
    <div className="drawing-area">
      <div className="palette">
        <SketchPicker
          color={selectedColor}
          onChangeComplete={(color) => setSelectedColor(color.hex)}
        />
      </div>
      <div className="canvas-area">
        <Stage
          ref={ref} 
          width={256}
          height={256}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
        >
          <Layer>
            {rectangles.map((rect, i) => (
              <Rect key={i} {...rect} />
            ))}
            {newRect && <Rect {...newRect} />}
          </Layer>
        </Stage>
      </div>
      <div className="buttons">
        <button onClick={clearDrawing} className="clear-button">
          <img src={refreshIcon} alt="Сбросить" className="clear-icon" />
        </button>
      </div>
    </div>
  );
});

export default DrawingArea;
